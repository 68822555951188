import logo from './ssa.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
        <p>
          <a href="https://www.facebook.com/streetschoolassociation" class="fa fa-facebook"></a>
          <a href="https://www.linkedin.com/company/street-school-association-pakistan/" class="fa fa-linkedin"></a>
          <a href="https://www.instagram.com/streetschoolassociation/" class="fa fa-instagram"></a>
        </p>
      </header>
    </div>
  );
}

export default App;